import React, { memo } from 'react'
import { EIconName, EIconSize, Icon, IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Button } from '@/components/ui'
import { Fading } from '@/components/ui/animations'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { ISearchInputWrapperProps } from './interfaces'

const resettingIconProps: IIconProps = { name: EIconName.Exit }

const SearchInputWrapper: React.FC<ISearchInputWrapperProps> = ({ withActions, onReset, children }) => {
  const { isMobile } = useDeviceType()

  return (
    <div
      className={classNames('search-input search-input_left', {
        'search-input-actions_with-actions': withActions,
      })}
    >
      <span className="search-input__icon search-input__icon_left">
        <Icon name={EIconName.Search} size={isMobile ? EIconSize.XS : EIconSize.M} />
      </span>
      {children}
      <Fading isVisible={withActions}>
        <div className="search-input-actions">
          <Button isUnstyled onClick={onReset} iconProps={resettingIconProps} className="search-input-actions__reset" />
          <Button
            className="button button_green search-input-actions__search text_small-from-sm"
            textId="searchInput.search"
            type="submit"
          />
        </div>
      </Fading>
    </div>
  )
}

export default memo(SearchInputWrapper) as typeof SearchInputWrapper
