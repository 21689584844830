import React, { forwardRef, memo, ReactNode, useCallback, useImperativeHandle, useMemo, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import AutosuggestView from '@/components/ui/fields/Autosuggest/AutosuggestView'
import { TOnSuggestionsFetchRequested } from '@/components/ui/fields/Autosuggest/interfaces'
import { injectMessages } from '@/logic/translation/utils'
import { getUserRegion } from '@/logic/userRegion'
import { RootState } from '@/redux/interfaces'
import { MarketSearchService } from '@/services'
import { TMarketSearchSuggestion } from '@/services/MarketSearchService/interfaces'
import { useIsomorphicLayoutEffect } from '@/utils/hooks'
import { convertToSuggestionsWithDividers, onSuggestionSelected } from './helpers'
import { ISearchInputWithSuggestionsProps, ISearchInputWithSuggestionsRef } from './interfaces'
import messages from './messages.json'
import SearchInputWithSuggestionsWrapper from './SearchInputWithSuggestionsWrapper'
import SearchInputWrapper from './SearchInputWrapper'
import SuggestionCard from './SuggestionCard'
import './SearchInputWithSuggestions.scss'

const marketSearchServices = new MarketSearchService()

injectMessages(messages)

const DEFAULT_META = {}
const getSuggestionValue = (suggestion: TMarketSearchSuggestion) => suggestion.name
const renderSuggestionComponent = (suggestion: TMarketSearchSuggestion) => <SuggestionCard suggestion={suggestion} key={suggestion.id} />

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const SearchInputWithSuggestions = forwardRef<ISearchInputWithSuggestionsRef, ISearchInputWithSuggestionsProps>((props, forwardedRef) => {
  const {
    name,
    className,
    customInputProps,
    shouldOpenInModal = false,
    customInputWrapper,
    value = '',
    meta = DEFAULT_META,
    onSuggestChange,
    onChange,
    onInput,
  } = props

  const { userRegion } = useSelector(mapState)

  const [internalValue, setInternalValue] = useState<string>(value ?? '')

  useIsomorphicLayoutEffect(() => setInternalValue(value ?? ''), [value])
  useIsomorphicLayoutEffect(() => onChange?.(internalValue ?? ''), [internalValue, onChange])

  const [dropdownKey, setDropdownKey] = useReducer(prevState => prevState + 1, 0)
  const [shouldOpenModal, setModalVisibility] = useState<boolean>(false)

  const { showModal, hideModal } = useMemo(
    () => ({
      showModal: () => setModalVisibility(shouldOpenInModal),
      hideModal: () => setModalVisibility(false),
    }),
    [setModalVisibility, shouldOpenInModal],
  )

  const resetForm = useCallback(() => {
    setDropdownKey()
    setInternalValue('')
  }, [setDropdownKey, setInternalValue])

  const suggestionSelected: typeof onSuggestionSelected = event => {
    // По выбору сущности подсказки поиск сбрасывается, чтобы при выборе Категории
    // не подставлялся текст категории
    setTimeout(() => {
      setInternalValue('')
      hideModal()
    }, 0)

    return onSuggestionSelected(event)
  }

  useImperativeHandle(forwardedRef, () => ({ hideModal }), [hideModal])

  const inputProps = useMemo(
    () => ({ placeholderId: customInputProps?.placeholder ?? 'searchInput.placeholder', autoFocus: shouldOpenModal, ...customInputProps }),
    [customInputProps, shouldOpenModal],
  )

  const withActions = (shouldOpenInModal ? shouldOpenModal : true) && Boolean(internalValue)

  const renderCustomInput = useCallback(
    (TextInput: ReactNode) => {
      if (customInputWrapper) {
        const InputWrapper = customInputWrapper

        return (
          <InputWrapper onReset={resetForm} withActions={withActions}>
            {TextInput}
          </InputWrapper>
        )
      }

      return (
        <SearchInputWrapper onReset={resetForm} withActions={withActions}>
          {TextInput}
        </SearchInputWrapper>
      )
    },
    [resetForm, withActions, customInputWrapper],
  )

  const onSuggestionsFetchRequested: TOnSuggestionsFetchRequested<TMarketSearchSuggestion> = useCallback(
    async ({ value: query }) => {
      const suggestions = await marketSearchServices.getSuggestions(query, { regionCode: userRegion.code })

      return convertToSuggestionsWithDividers(suggestions)
    },
    [userRegion],
  )

  return (
    <form
      className={classNames('search-input-with-suggestions', className, {
        'search-input-with-suggestions_has-actions': withActions,
        'search-input-with-suggestions_in-modal': shouldOpenModal,
      })}
      onSubmit={event => {
        event.preventDefault()
        onInput?.(internalValue)
      }}
    >
      <SearchInputWithSuggestionsWrapper shouldOpenModal={shouldOpenModal} onCloseModal={hideModal}>
        <AutosuggestView<TMarketSearchSuggestion>
          name={name}
          defaultInputValue={internalValue}
          onSuggestChange={onSuggestChange}
          renderCustomInput={renderCustomInput}
          meta={meta}
          onSuggestionSelected={suggestionSelected}
          suggestionComponent={renderSuggestionComponent}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onInputChange={setInternalValue}
          onInputFocus={showModal}
          customInputProps={inputProps}
          shouldAlwaysRenderSuggestions={shouldOpenInModal}
          key={dropdownKey}
        />
      </SearchInputWithSuggestionsWrapper>
    </form>
  )
})

export default memo(SearchInputWithSuggestions)
